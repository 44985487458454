import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import ValorantGuides from "@/data-models/valorant-guides.mjs";
import MetaMapsModel from "@/data-models/valorant-meta-maps.mjs";
import ValorantRelatedGuides from "@/data-models/valorant-related-guides.mjs";
import {
  getGuides,
  getRelatedLineups,
  getValorantConstDataByType,
} from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import type { Sides } from "@/game-val/guides-utils.mjs";
import { getGuideKeyStr, getParamsObj } from "@/game-val/guides-utils.mjs";

export default async function fetchData(
  _params: string[],
  searchParams: URLSearchParams,
  state: RouteState,
) {
  const lineupParams = new URLSearchParams(searchParams);

  const page = (state.transient?.page as number) || 1;
  lineupParams.set("page", page.toString());

  if (!lineupParams.get("side")) {
    // Default to attacking
    lineupParams.set("side", "attacking" satisfies Sides);
  }

  const requiredPromises: Promise<unknown>[] = [
    fetchConstants(),
    getData(
      getValorantConstDataByType("maps"),
      MetaMapsModel,
      ["val", "meta", "maps"],
      {
        networkBackOffTime: 1 * 24 * 60 * 60 * 1000, // 1 day
      },
    ),
    getData(
      getGuides(getParamsObj(lineupParams, false)),
      ValorantGuides,
      ["val", "guides", getGuideKeyStr(lineupParams, true)],
      {
        shouldFetchIfPathExists: true,
        mergeFn(current, next) {
          if (next.page === 1) return next;

          return {
            ...next,
            list: [...current.list, ...next.list],
          };
        },
      },
    ),
  ];

  const selectedLineup = lineupParams.get("lineup");

  if (selectedLineup) {
    requiredPromises.push(
      getData(
        getRelatedLineups(selectedLineup),
        ValorantRelatedGuides,
        ["val", "relatedGuides", selectedLineup],
        {
          networkBackOffTime: 1 * 24 * 60 * 60 * 1000,
        },
      ),
    );
  }

  await Promise.all(requiredPromises);
}
