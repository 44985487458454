import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";
import { tipModel } from "@/data-models/valorant-guides.mjs";

const relatedGuidesModel = {
  tip: tipModel,
  agentTips: [tipModel],
  mapTips: [tipModel],
} satisfies ModelDefinition;

const ValorantRelatedGuidesModel = createModel(relatedGuidesModel);
export type ValorantRelatedGuides = FromModel<typeof relatedGuidesModel>;

export default ValorantRelatedGuidesModel;
